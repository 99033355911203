import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Modal,
  Button,
  Form,
  InputGroup,
  Row,
  ListGroup,
  Container,
} from "@themesberg/react-bootstrap";
import {
  getAgendaAction,
  eliminarAgendaAction,
  agregarAgendaAction,
} from "../../actions/agendaActions";
import { getPacientesAction } from "../../actions/pacientesActions";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clienteAxios from "../../config/axios";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import DefaultUser from "../../assets/img/team/default-user.png";
import "react-datetime/css/react-datetime.css";
import Filtro from "../../components/Filtro";
import { formatAMPM } from "../../helpers";

export default () => {
  const dispatch = useDispatch();

  const { agenda, agendas } = useSelector((state) => state.agenda);
  const { pacientes } = useSelector((state) => state.pacientes);

  const { respuestaFiltro } = useSelector(state => state.filtro)
  const modelo = 'Agenda.Agenda';
  const opcionesFiltro  = [
    { valor: 'cliente_proximas_citas', label: 'Nombre cliente', tipo: 'text', criteria: ['agenda'] }
  ]

  const [respuestaBusqueda, setRespuesta] = useState([]);

  useEffect(() => {
    setRespuesta(respuestaFiltro);
  }, [respuestaFiltro])

  useEffect(() => {
    if(respuestaFiltro.length > 0){
      setRespuesta([])
    }
    dispatch(getAgendaAction());
    dispatch(getPacientesAction());
  }, []);

  const [fecha, setFecha] = useState("");
  const [titulo, setTitulo] = useState("");
  const [modalAddEvent, setModalAddEvent] = useState(false);

  const [state, setState] = useState({
    query: "",
    options: [],
    isLoading: false,
    clienteSeleccionado: null,
  });

  var _cacheCliente = {};

  const _handleInputChangeCliente = (query) => {
    state.query = query;
  };

  const makeAndHandleRequestCliente = (query) => {
    const config = { params: { query } };

    return clienteAxios.get("typeahead/clientes", config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  const _handleSearchCliente = (query) => {
    if (_cacheCliente[query]) {
      setState({
        ...state,
        options: _cacheCliente[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequestCliente(query).then((resp) => {
      _cacheCliente[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };

  const onChangeCliente = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        clienteSeleccionado: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        clienteSeleccionado: null,
      });
    }
  };

  const addEvent = (date) => {
    setFecha(date);
    setModalAddEvent(true);
  };

  const onConfirmEvent = (e) => {
    e.preventDefault();

    const data = {
      fecha: fecha,
      titulo: titulo,
      id_cliente: state.clienteSeleccionado?.id,
    };

    dispatch(agregarAgendaAction(data));
  };

  const deleteEvent = (index) => {
    dispatch(eliminarAgendaAction(agendas[index]));
  };

  const deleteEventByIdAgenda = (id_agenda) => {
    dispatch(eliminarAgendaAction({ id: id_agenda }));
  };

  const handleEventModalClose = () => {
    setModalAddEvent(false);
  };

  const RespuestaBusqueda = () => {
    let resultado = [];
    if (respuestaBusqueda.length > 0) {
        respuestaBusqueda.map((item, index) => {
          const date = new Date(Date.parse(item.fecha));

          resultado.push(
            <Card style={{ width: '18rem', marginRight: '10px' }}>
              <Card.Body>
                <Card.Title>{item.titulo}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                <ListGroup variant="flush">
                  <ListGroup.Item style={{padding:"0px"}}><i class="fas fa-calendar"></i> { date.toUTCString() }</ListGroup.Item>
                  <ListGroup.Item style={{padding:"0px"}}><i class="fas fa-clock"></i> { formatAMPM(date, true) }</ListGroup.Item>
                  <ListGroup.Item style={{padding:"0px"}}><i class="fas fa-user"></i> { item.cliente?.nombre_completo }</ListGroup.Item>
                </ListGroup>
                </Card.Subtitle>
                <Card.Text>
                </Card.Text>
                <Card.Footer>
                  <Button className="d-block mx-auto" onClick={() => deleteEventByIdAgenda(item.id)} variant="danger" size="sm">
                    Eliminar
                  </Button>
                </Card.Footer>
              </Card.Body>
            </Card>
          )
      })
    }

    return resultado;
  }

  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm mt-4"
      >
        <Card.Body>
          <h5 className="mb-4">Agenda</h5>

          <Filtro 
            defaultSelected="cliente_proximas_citas" 
            buscarTodo={false}
            opciones={opcionesFiltro} 
            modelo={modelo}
          />
          
          <Row className="mb-4">
            <Container>
              <Row>
                { respuestaBusqueda.length > 0 &&
                  <RespuestaBusqueda />
                }
              </Row>
            </Container>
          </Row>
        </Card.Body>
      </Card>

      <Modal
        as={Modal.Dialog}
        size="sm"
        centered
        show={modalAddEvent}
        onHide={handleEventModalClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Agregar nuevo evento</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleEventModalClose}
          />
        </Modal.Header>
        <Form onSubmit={onConfirmEvent}>
          <Modal.Body>
          <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder='Titulo del evento'
                onChange={(e) => setTitulo(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Datetime
                timeFormat={true}
                closeOnSelect={false}
                onChange={setFecha}
                utc={true}
                value={fecha}
                initialViewMode="days"
                dateFormat="YYYY-MM-DD HH:mm"
                renderInput={(propsDate, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      value={fecha ? moment(fecha).format("YYYY-MM-DD HH:mm") : ""}
                      placeholder="Selecciona una fecha*"
                      onFocus={openCalendar}
                      onChange={(e) => setFecha(e.target.value)}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <AsyncTypeahead
                {...state}
                clearButton
                id="async-clientes"
                labelKey="nombre_completo"
                minLength={2}
                onInputChange={_handleInputChangeCliente}
                onSearch={_handleSearchCliente}
                onChange={onChangeCliente}
                placeholder="Buscar cliente*"
                renderMenuItemChildren={(option) => (
                  <div key={option.id}>
                    <img
                      alt={option.nombre_completo}
                      src={DefaultUser}
                      style={{
                        height: "24px",
                        marginRight: "10px",
                        width: "24px",
                      }}
                    />
                    <span>{option.nombre_completo}</span>
                  </div>
                )}
                useCache={false}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleEventModalClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              Agregar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
