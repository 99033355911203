import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
  Nav,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDatosAdicionalesAction } from "../../actions/datosAdicionalesActions";
import DatosAdicionalesRender from "../../components/DatosAdicionales/DatosAdicionalesRender";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import { getTratamientosAction } from "../../actions/tratamientosActions";
import { getProductosAction } from "../../actions/productosActions";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { ScrollPanel } from "primereact/scrollpanel";

export default (props) => {
  const dispatch = useDispatch();
  const stepperRef = useRef(null);

  const [showModalAgregar, setShowModalAgregar] = useState(false);
  const { datosAdicionales, tabsDatosAdicionales } = useSelector(
    (state) => state.datosAdicionales
  );
  const { datosAdicionalesDetalles } = useSelector(
    (state) => state.datosAdicionalesDetalles
  );

  const { tratamientos } = useSelector((state) => state.tratamientos);

  const { loadingProductosVenta, productos } = useSelector(
    (state) => state.venta
  );

  const [agregar, guardar] = useState({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "",
    genero: "",
    telefono: "",
    correo_electronico: "",
    es_prospecto: true,
    productos_servicios: [],
  });

  useEffect(() => {
    setShowModalAgregar(props.showModal);
  }, [props.showModal]);

  useEffect(() => {
    dispatch(getProductosAction());
    dispatch(getTratamientosAction());
  }, []);

  const submitForm = (e) => {
    e.preventDefault();

    clienteAxios.post("clientes/prospectos", agregar).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        const prospectos = respuesta.data.data;

        agregar.nombre = "";
        agregar.apellido_paterno = "";
        agregar.apellido_materno = "";
        agregar.correo_electronico = "";
        agregar.genero = "";
        agregar.telefono = "";
        agregar.fecha_nacimiento = "";

        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
      }
    });
  };

  const handleClose = () => {
    setShowModalAgregar(false);
    props.onHide();
  };

  const onChangeForm = (e) => {
    guardar({
      ...agregar,
      [e.target.name]: e.target.value,
    });
  };

  const onClickAgregarServicio = (row) => {
    const existeServicio = agregar.productos_servicios.find(
      (servicio) => servicio.id == row.id
    );

    if (existeServicio) {
      return;
    }

    agregar.productos_servicios.push(row);
    guardar({
      ...agregar,
    });
  };

  const onDeleteServicio = (row) => {
    agregar.productos_servicios = agregar.productos_servicios.filter(
      (servicio) => servicio.id != row.id
    );

    guardar({
      ...agregar,
    });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalAgregar}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="h6">Agregar prospecto</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }}>
            <StepperPanel header="Datos Generales">
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Nombre(s)*</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombre"
                      placeholder="Nombre(s)"
                      value={agregar.nombre}
                      onChange={onChangeForm}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Apellido paterno*</Form.Label>
                    <Form.Control
                      type="text"
                      name="apellido_paterno"
                      placeholder="Apellido paterno"
                      value={agregar.apellido_paterno}
                      onChange={onChangeForm}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Apellido materno</Form.Label>
                    <Form.Control
                      type="text"
                      name="apellido_materno"
                      placeholder="Apellido materno"
                      value={agregar.apellido_materno}
                      onChange={onChangeForm}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>Fecha nacimiento</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_nacimiento"
                  placeholder="Fecha de nacimiento"
                  value={agregar.fecha_nacimiento}
                  onChange={onChangeForm}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label as="legend" column="sm">
                  Genero
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  name="genero"
                  label="Masculino"
                  value="M"
                  onChange={onChangeForm}
                />
                <Form.Check
                  inline
                  type="radio"
                  name="genero"
                  label="Femenino"
                  value="F"
                  onChange={onChangeForm}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Correo electronico</Form.Label>
                <Form.Control
                  type="email"
                  name="correo_electronico"
                  placeholder="Correo electronico"
                  value={agregar.correo_electronico}
                  onChange={onChangeForm}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Teléfono*</Form.Label>
                <Form.Control
                  type="number"
                  name="telefono"
                  placeholder="Teléfono"
                  value={agregar.telefono}
                  onChange={onChangeForm}
                />
              </Form.Group>

              <Row>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => stepperRef.current.nextCallback()}
                >
                  Siguiente
                </Button>
              </Row>
            </StepperPanel>

            <StepperPanel header="Servicios/productos de interes">
              <ScrollPanel
                style={{ width: "100%", height: "400px" }}
                className="custombar2"
              >
                <Row xs={2} sm={2} lg={3} xl={4} className="g-4">
                  {tratamientos.map((tratamiento) => {
                    return (
                      <Col key={`tratamiento-${tratamiento.id}`}>
                        <Card className="text-center h-100">
                          {tratamiento.imagen ? (
                            <Card.Img
                              variant="top"
                              src={
                                process.env.REACT_APP_API_BASE_URL +
                                "/image/" +
                                tratamiento.imagen
                              }
                              style={{
                                height: "130px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <Card.Img
                              variant="top"
                              src="https://www.lwf.org/images/emptyimg.png"
                              style={{
                                height: "130px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                          <Card.Body style={{ padding: "10px" }}>
                            <Card.Title style={{ fontSize: "15px" }}>
                              {tratamiento.nombre}
                            </Card.Title>
                          </Card.Body>
                          <Card.Footer
                            style={{ padding: "6px", fontWeight: "bold" }}
                          >
                            <Button
                              className="btn-sm"
                              variant="primary"
                              onClick={() =>
                                onClickAgregarServicio(tratamiento)
                              }
                              disabled={
                                agregar.productos_servicios.find(
                                  (servicio) => servicio.id == tratamiento.id
                                )
                                  ? true
                                  : false
                              }
                            >
                              Agregar
                            </Button>
                          </Card.Footer>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </ScrollPanel>
              <hr />

              <Card border="light" className="shadow-sm">
                <Card.Body>
                  <table className="table table-centered table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Comisionista asignado</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agregar.productos_servicios.map((producto, index) => (
                        <tr key={index}>
                          <td>{producto.nombre}</td>
                          <td></td>
                          <td>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => onDeleteServicio(producto)}
                            >
                              Eliminar
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>

              <Row style={{ marginTop: "30px" }}>
                <Button type="submit" variant="primary">
                  Agregar
                </Button>
              </Row>
            </StepperPanel>
          </Stepper>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
