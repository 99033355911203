import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button } from "@themesberg/react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import clienteAxios from "../../config/axios";
import ModalFormEmpleadoAusencia from "../../pages/Empleados/ModalFormEmpleadoAusencia";
import { ConfirmModal } from "../../components/ConfirmModal";
import Swal from "sweetalert2";

export default (props) => {
  const dispatch = useDispatch();

  const [ausencias, setAusencias] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);

  useEffect(() => {
    setShowModal(props.showModal);

    if (props.showModal === true) {
      getAusencias();
    }
  }, [props.showModal]);

  const getAusencias = () => {
    clienteAxios.get("empleados/ausencias").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setAusencias(respuesta.data.data);
      }
    });
  };

  const columns = [
    {
      key: "id",
      text: "ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      text: "Empleado",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          record.empleado?.nombre +
          " " +
          record.empleado?.apellido_paterno +
          " " +
          (record.empleado?.apellido_materno || "")
        );
      },
    },
    {
      text: "Todo el dia",
      cell: (record) => {
        return record.todo_el_dia ? "SI" : "NO";
      },
    },
    {
      key: "fecha_inicio",
      text: "Desde",
      sortable: true,
    },
    {
      key: "fecha_fin",
      text: "Hasta",
      sortable: true,
    },
    {
      key: "hora_inicio",
      text: "Hora inicio",
      sortable: true,
    },
    {
      key: "hora_fin",
      text: "Hora fin",
      sortable: true,
    },
    {
      key: "motivo",
      text: "Motivo",
      sortable: true,
    },
    {
      text: "Acciones",
      cell: (record) => {
        return (
          <Button
            variant="danger"
            size="sm"
            className="me-2"
            onClick={(e) => {
              deleteRecord(record);
            }}
          >
            <i class="fas fa-trash"></i>
          </Button>
        );
      },
    },
  ];

  const handleClose = () => {
    props.onHide();
    setShowModal(false);
  };

  const eliminarAusencia = (id) => {
    clienteAxios.delete(`empleados/ausencias/${id}`).then((respuesta) => {
      if (respuesta && respuesta.data.success === true) {
        setAusencias(ausencias.filter((ausencia) => ausencia.id !== id));
        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
      }
    });
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: eliminarAusencia,
        args: [record.id],
      },
    ];

    ConfirmModal(callbacks);
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Nueva",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nueva
        </span>,
      ],
      onClick: (event) => {
        setShowModalForm(true);
      },
    },
  ];

  const config = {
    button: {
      extra: true,
    },
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ausencias</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="table-wrapper table-responsive shadow-sm">
          <ReactDatatable
            hover
            records={ausencias}
            columns={columns}
            extraButtons={extraButtons}
            config={config}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalFormEmpleadoAusencia
        showModal={showModalForm}
        onHide={() => {
          setShowModalForm(false);
        }}
        onCreate={(data) => {
          setAusencias([...ausencias, data]);
          setShowModalForm(false);
        }}
      />
    </>
  );
};
