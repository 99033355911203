import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@mkikets/react-datatable";

import { ConfirmModal } from "../../components/ConfirmModal";
import { Fragment } from "react";
import clienteAxios from "../../config/axios";
import {
  actualizarBusquedaFiltroAction,
  busquedaFiltroAction,
} from "../../actions/filtroActions";
import Filtro from "../../components/Filtro";
import Swal from "sweetalert2";
import ModalGenerarNomina from "./ModalGenerarNomina";
import Salarios from "./Salarios";
import { getRolesAction } from "../../actions/rolesActions";
import DataTable from "react-data-table-component";
import { numberFormat } from "../../helpers";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Tag } from "primereact/tag";

export default () => {
  const dispatch = useDispatch();
  const { loadingFiltro, respuestaFiltro, request } = useSelector(
    (state) => state.filtro
  );

  const modelo = "Tesoreria.Nomina.Nominas";
  const criteriasObligatorios = ["order-id"];

  const [showModalGenerarNomina, setShowModalGenerarNomina] = useState(false);

  const [requestFiltro, guardarRequestFiltro] = useState({
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    dispatch(
      busquedaFiltroAction(modelo, {
        offset: 0,
        limit: 10,
        criteria: ["all", "order-id"],
      })
    );
  }, []);

  const onChangeDT = (event) => {
    requestFiltro.limit = event.page_size;
    requestFiltro.offset = event.page_size * (event.page_number - 1);
    guardarRequestFiltro({ ...requestFiltro });

    dispatch(
      busquedaFiltroAction(modelo, {
        offset: requestFiltro.offset,
        limit: requestFiltro.limit,
        criteria: ["all", "order-id"],
        valor: request.valor,
      })
    );
  };

  const columns = [
    {
      name: "ID Nomina",
      selector: (row) => row.id,
    },
    {
      name: "Fecha inicio",
      selector: (row) => row.fecha_inicio,
      width: "130px",
    },
    {
      name: "Fecha fin",
      selector: (row) => row.fecha_fin,
      width: "130px",
    },
    {
      name: "Empleado",
      selector: (record) => {
        return record.salario.empleado
          ? record.salario?.empleado.nombre +
              " " +
              record.salario?.empleado.apellido_paterno
          : "NO ENCONTRADO";
      },
      width: "300px",
    },
    {
      name: "Sueldo base ($)",
      selector: (record) => {
        return numberFormat(record.sueldo_base);
      },
      width: "150px",
    },
    {
      name: "Cargos ($)",
      selector: (record) => {
        return numberFormat(record.monto_cargos);
      },
    },
    {
      name: "Bonos ($)",
      selector: (record) => {
        return numberFormat(record.monto_bonos);
      },
    },
    {
      name: "Impuestos ($)",
      selector: (record) => {
        return numberFormat(record.monto_impuestos);
      },
    },
    {
      name: "Total ($)",
      selector: (record) => {
        return numberFormat(record.total);
      },
    },
    {
      name: "Pagado",
      cell: (record) => {
        return record.pagado ? "SI" : "NO";
      },
    },
    {
      name: "Acciones",
      selector: (record) => {
        return (
          <>
            <Fragment>
              {record.pagado === false && (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>Marcar como pagado</Tooltip>}
                >
                  <button
                    onClick={() => marcarComoPagada(record.id)}
                    className="btn btn-success btn-sm"
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    <i class="fas fa-check"></i>
                  </button>
                </OverlayTrigger>
              )}
            </Fragment>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Eliminar</Tooltip>}
              >
                <button
                  onClick={() => eliminarNomina(record.id)}
                  className="btn btn-danger btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-trash"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
      width: "150px",
    },
  ];

  const subHeaderComponent = (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <button
          className="btn btn-success"
          onClick={() => {
            setShowModalGenerarNomina(true);
          }}
        >
          <i class="fas fa-arrow-alt-circle-up"></i> Generar nominas
        </button>
      </div>
    </>
  );

  const opcionesFiltro = [
    { valor: "id", label: "ID", tipo: "number", criteria: ["equal"] },
    {
      valor: "fecha",
      label: "Fecha",
      tipo: "date",
      criteria: ["date", "order-id"],
    },
  ];

  const marcarComoPagada = (idNomina) => {
    clienteAxios
      .put("nominas/" + idNomina + "/marcar-pagado")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          console.log(respuestaFiltro);
          const rows = respuestaFiltro.data.map((row) => {
            if (row.id === idNomina) {
              row.pagado = true;
            }

            return row;
          });

          console.log(rows);

          dispatch(actualizarBusquedaFiltroAction(rows));

          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  const eliminarNomina = (idNomina) => {
    clienteAxios.delete("nominas/" + idNomina).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        const rows = respuestaFiltro.data.filter((row) => {
          return row.id !== idNomina;
        });

        dispatch(actualizarBusquedaFiltroAction(rows));

        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
      }
    });
  };

  const getTipoConcepto = (value) => {
    switch (value) {
      case "BONO":
        return "success";

      case "CARGO":
        return "danger";

      default:
        return null;
    }
  };

  const ExpandedComponent = ({ data }) => {
    console.log(data.conceptos_detalles);
    return (
      <>
        <DataTable
          columns={[
            {
              name: "ID",
              selector: (row) => row.id,
              width: "100px",
            },
            {
              name: "Concepto",
              selector: (row) => row.salario_concepto.concepto.descripcion,
              maxWidth: "300px",
            },
            {
              name: "Fecha",
              selector: (row) => row.salario_concepto.created_at,
              width: "150px",
            },
            {
              name: "Tipo",
              selector: (row) => {
                return (
                  <Tag
                    value={row.salario_concepto.concepto.atributo1_valor}
                    severity={getTipoConcepto(
                      row.salario_concepto.concepto.atributo1_valor
                    )}
                  ></Tag>
                );
              },
              width: "100px",
            },
            {
              name: "Monto",
              selector: (row) => {
                return numberFormat(row.monto);
              },
            },
          ]}
          data={data.conceptos_detalles}
          responsive
          dense
          striped
        />
      </>
    );
  };

  const expandableIcon = <KeyboardArrowRightIcon color="#000" />;
  const expandableIconCollapsed = <KeyboardArrowDownIcon color="#000" />;

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Nominas</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Nominas</h5>

          <Tabs defaultActiveKey="nominas" id="uncontrolled-tab-example" fill>
            <Tab eventKey="nominas" title="Nominas">
              <br></br>
              <br></br>

              <Filtro
                opciones={opcionesFiltro}
                modelo={modelo}
                criteriasObligatorios={criteriasObligatorios}
                request={requestFiltro}
              />

              <DataTable
                columns={columns}
                data={respuestaFiltro.data ?? []}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
                expandableIcon={{
                  collapsed: expandableIcon,
                  expanded: expandableIconCollapsed,
                }}
                expanded
                responsive
                striped
                subHeader
                subHeaderAlign="right"
                subHeaderWrap
                subHeaderComponent={subHeaderComponent}
              />

              {/*
              <ReactDatatable
                hover
                dynamic
                records={respuestaFiltro.data ?? []}
                columns={columns}
                extraButtons={extraButtons}
                config={configDataTable}
                loading={loadingFiltro}
                onChange={onChangeDT}
                total_record={respuestaFiltro.count ?? 0}
              />
              */}
            </Tab>
            <Tab eventKey="salarios" title="Salarios">
              <br></br>
              <br></br>
              <Salarios />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>

      <ModalGenerarNomina
        showModal={showModalGenerarNomina}
        onHide={() => {
          setShowModalGenerarNomina(false);
        }}
        onSubmit={(nuevasNominas) => {
          dispatch(
            busquedaFiltroAction(modelo, {
              offset: requestFiltro.offset,
              limit: requestFiltro.limit,
              criteria: ["all", "order-id"],
              valor: request.valor,
            })
          );
          setShowModalGenerarNomina(false);
        }}
      />
    </>
  );
};
