import React, { Fragment, useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { Tooltip, OverlayTrigger } from "@themesberg/react-bootstrap";
import { columnaSucursalDT } from "../helpers";

export default (props) => {
  let columns = [
    ...props.columns,
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            {props.editRecord && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Editar</Tooltip>}
              >
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => props.editRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-edit"></i>
                </button>
              </OverlayTrigger>
            )}
            {props.deleteRecord && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Eliminar</Tooltip>}
              >
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => props.deleteRecord(record)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </OverlayTrigger>
            )}
            {props.acciones?.map((value, index) => {
              if (value.type && value.type == "fragment") {
                return value.render(record);
              }
              
              return (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{value.description}</Tooltip>}
                >
                  <button
                    className={value.className}
                    onClick={() => value.event(record)}
                    style={value.style}
                  >
                    <i className={value.icon}></i>
                  </button>
                </OverlayTrigger>
              );
            })}
          </Fragment>
        );
      },
    },
  ];

  const sucursalDT = columnaSucursalDT();

  if (sucursalDT) {
    columns.splice(columns.length - 1, 0, sucursalDT);
  }

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
    language: {
      length_menu: "Mostrar _MENU_ registros por página",
      filter: "Buscar",
      info: "Mostrando _START_ a _END_ de _TOTAL_ resultados",
      pagination: {
        first: "Primero",
        previous: "Previa",
        next: "Próximo",
        last: "Última",
      },
    },
  };

  return (
    <>
      {props.loading ? (
        <div class="spinner-border text-info mx-auto d-block" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      ) : (
        <ReactDatatable
          hover
          config={Object.assign(config, props.config)}
          records={props.state.records}
          columns={columns}
          extraButtons={props.extraButtons ? props.extraButtons : []}
        />
      )}
    </>
  );
};
