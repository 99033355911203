import moment from "moment-timezone";

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export const numberFormat = (amount) => {
  const options = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", options);

  return numberFormat.format(amount);
};

export const formatAMPM = (date, utc = false) => {
  var hours = utc ? date.getUTCHours() : date.getHours();
  var minutes = utc ? date.getUTCMinutes() : date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const differenceDates = (date1, date2, interval) => {
  return moment(date1).diff(moment(date2), interval, false);
};

export const localeDateString = (date) => {
  var options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("es-ES", options);
};

export const removeDuplicates = (arr) => {
  let unique = arr.reduce(function (acc, curr) {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);
  return unique;
};

export const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const columnaSucursalDT = () => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  if (user && user.id_sucursal != null) {
    return null;
  }

  return {
    text: "Sucursal",
    align: "left",
    sortable: true,
    cell: (record) => {
      return record.sucursal?.nombre || "TODAS";
    },
  };
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

export const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
export const formatAMPMInputTime = (timeValue) => {
  let [hours, minutes] = timeValue.split(":").map(Number);
  let period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convierte 0 a 12 para formato 12 horas
  return `${hours}:${minutes.toString().padStart(2, "0")} ${period}`;
};
