import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Breadcrumb } from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";
import { ConfirmModal } from "../../components/ConfirmModal";
import DatosAdicionales from "../../components/DatosAdicionales/DatosAdicionales";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import AgregarProspecto from "./AgregarProspecto";

export default () => {
  const dispatch = useDispatch();

  const [prospectos, setProspectos] = useState([]);
  const [showModalAgregarProspecto, setShowModalAgregarProspecto] =
    useState(false);
  const [loadingProspectos, setLoadingProspectos] = useState(false);

  const obtenerProspectos = () => {
    setLoadingProspectos(true);

    clienteAxios.get("clientes/prospectos").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        const prospectos = respuesta.data.data;

        setProspectos(prospectos);
        setLoadingProspectos(false);
      }
    });
  };

  const eliminarProspecto = (prospecto) => {
    clienteAxios
      .delete("clientes/prospectos/" + prospecto.id)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setProspectos(
            prospectos.filter(
              (prospecto) => prospecto.id !== respuesta.data.data.id
            )
          );
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  let columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      key: "nombre_completo",
      text: "Nombre",
      align: "left",
      sortable: true,
    },
    {
      key: "telefono",
      text: "Teléfono",
      align: "left",
      sortable: true,
    },
  ];

  useEffect(() => {
    obtenerProspectos();
  }, []);

  const state = {
    records: prospectos,
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar prospecto",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo
        </span>,
      ],
      onClick: (event) => {
        setShowModalAgregarProspecto(true);
      },
    }
  ];

  const editRecord = (record) => {
    
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: eliminarProspecto,
        args: [record],
      },
    ];

    ConfirmModal(callbacks);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Prospectos</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Prospectos</h5>
          <DataTable
            columns={columns}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            state={state}
            extraButtons={extraButtons}
            loading={loadingProspectos}
          />
        </Card.Body>
      </Card>
      <AgregarProspecto
        showModal={showModalAgregarProspecto}
        onHide={() => setShowModalAgregarProspecto(false)}
      />
      <DatosAdicionales tabla="PROSPECTOS" />
    </>
  );
};
