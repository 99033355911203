import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Tab,
  Nav,
} from "@themesberg/react-bootstrap";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import Typeahead from "../../components/Typeahead";
import { formatAMPMInputTime } from "../../helpers";
import { useSelector } from "react-redux";

export default (props) => {
  const [showModal, setShowModal] = useState(false);
  const { sucursales } = useSelector((state) => state.sucursales);

  useEffect(() => {
    setShowModal(props.showModal);

    if (props.showModal === true) {
      guardar({
        empleado: null,
        fecha_inicio: "",
        fecha_fin: "",
        hora_inicio: "",
        hora_fin: "",
        todo_el_dia: false,
        motivo: "",
        todas_las_sucursales: false,
        sucursal: null,
      });
    }
  }, [props]);

  const [state, guardar] = useState({
    empleado: null,
    fecha_inicio: "",
    fecha_fin: "",
    hora_inicio: "",
    hora_fin: "",
    todo_el_dia: false,
    motivo: "",
    todas_las_sucursales: false,
    sucursal: null,
  });

  const submitForm = (e) => {
    e.preventDefault();

    const params = {
      id_empleado: state.empleado ? state.empleado.id : null,
      fecha_inicio: state.fecha_inicio,
      fecha_fin: state.fecha_fin,
      hora_inicio: state.hora_inicio ? formatAMPMInputTime(state.hora_inicio) : null,
      hora_fin: state.hora_fin ? formatAMPMInputTime(state.hora_fin) : null,
      todo_el_dia: state.todo_el_dia,
      motivo: state.motivo,
      id_sucursal: state.sucursal ? state.sucursal.id : null,
    };

    clienteAxios.post("empleados/ausencias", params).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        props.onCreate(respuesta.data.data);
        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
      }
    });
  };

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const onChangeSucursal = (e) => {
    const sucursalSeleccionada = sucursales.filter(
      (sucursal) => sucursal.id == e.target.value
    );
    guardar({
      ...state,
      sucursal: sucursalSeleccionada[0],
    });
  };

  const onChangeForm = (e) => {
    guardar({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckbox = (e) => {
    guardar({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  const onChangeComisionista = (selectedOption) => {
    if (selectedOption.length > 0) {
      guardar({
        ...state,
        empleado: selectedOption[0],
      });
    } else {
      guardar({
        ...state,
        empleado: null,
      });
    }
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="h6">Registrar ausencia</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Row className="float-right">
            <Col>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Todo el día"
                  name="todo_el_dia"
                  checked={state.todo_el_dia}
                  onChange={onChangeCheckbox}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Todas las sucursales"
                  name="todas_las_sucursales"
                  checked={state.todas_las_sucursales}
                  onChange={onChangeCheckbox}
                />
              </Form.Group>
            </Col>
          </Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Comisionista*</Form.Label>
              <Typeahead
                id="type_comisionista"
                url="typeahead/empleados"
                labelKey="label"
                placeholder="Buscar comisionista"
                onChange={(selected) => {
                  onChangeComisionista(selected);
                }}
                selected={state.empleado ? [state.empleado] : []}
              />
            </Form.Group>
          </Col>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Fecha inicio*</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_inicio"
                  value={state.fecha_inicio}
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Fecha fin*</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_fin"
                  value={state.fecha_fin}
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
          </Row>
          {state.todo_el_dia == false && (
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Hora inicio</Form.Label>
                  <Form.Control
                    type="time"
                    name="hora_inicio"
                    value={state.hora_inicio}
                    onChange={onChangeForm}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Hora fin</Form.Label>
                  <Form.Control
                    type="time"
                    name="hora_fin"
                    value={state.hora_fin}
                    onChange={onChangeForm}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          {state.todas_las_sucursales == false && (
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Sucursal</Form.Label>
                  <Form.Select onChange={(e) => onChangeSucursal(e)}>
                    <option>Seleccionar...</option>
                    {sucursales.map((sucursal) => (
                      <option key={sucursal.id} value={sucursal.id}>
                        {sucursal.nombre}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Form.Group className="mb-3">
                <Form.Label>Motivo*</Form.Label>
                <Form.Control
                  as="textarea"
                  name="motivo"
                  value={state.motivo}
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
