import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Breadcrumb, OverlayTrigger } from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";
import {
  comenzarEditarEmpleadoAction,
  comenzarAgregarEmpleadoAction,
  getEmpleadosAction,
  eliminarEmpleadoAction,
  comenzarListaComisionesAction,
  getEmpleadosComisionesAction,
} from "../../actions/empleadosActions";

import { ConfirmModal } from "../../components/ConfirmModal";
import AgregarEmpleado from "./AgregarEmpleado";
import EditarEmpleado from "./EditarEmpleado";
import Comisiones from "./Comisiones";
import {
  getDatosAdicionalesAction,
  getTabsDatosAdicionalesAction,
  setModalDatosAdicionalesAction,
} from "../../actions/datosAdicionalesActions";
import { getDatosAdicionalesDetallesAction } from "../../actions/datosAdicionalesDetallesActions";
import DatosAdicionales from "../../components/DatosAdicionales/DatosAdicionales";
import ModalEmpleadoPuestos from "./ModalEmpleadoPuestos";
import { columnaSucursalDT } from "../../helpers";
import { Button as PrimeButton } from "primereact/button";
import { Menu } from "primereact/menu";
import ModalEmpleadoHorarios from "./ModalEmpleadoHorarios";

export default () => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const menuRight = useRef(null);

  const { loadingEmpleados, empleado, empleados } = useSelector(
    (state) => state.empleados
  );
  const [showModalPuestos, setShowModalPuestos] = useState(false);
  const [showModalHorarios, setShowModalHorarios] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(getEmpleadosAction());
  }, []);

  const columns = [
    {
      key: "id",
      text: "ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "nombre",
      text: "Nombre",
      className: "nombre",
      align: "left",
      sortable: true,
    },
    {
      key: "apellido_paterno",
      text: "Apellido paterno",
      className: "apellido_paterno",
      sortable: true,
    },
    {
      key: "apellido_materno",
      text: "Apellido materno",
      className: "apellido_materno",
      sortable: true,
    },
    {
      key: "telefono",
      text: "Teléfono",
      className: "telefono",
      sortable: true,
    },
    {
      key: "comision",
      text: "Comisión %",
      className: "comision",
      sortable: true,
    },
  ];

  const state = {
    records: empleados,
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar empleado",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo empleado
        </span>,
      ],
      onClick: (event) => {
        dispatch(comenzarAgregarEmpleadoAction());
        dispatch(getTabsDatosAdicionalesAction("EMPLEADOS"));
        dispatch(getDatosAdicionalesAction("EMPLEADOS"));
      },
    },
    {
      className: "btn btn-primary",
      title: "Datos adicionales",
      children: [
        <span>
          <i class="fas fa-plus"></i> Datos adicionales
        </span>,
      ],
      onClick: (event) => {
        dispatch(setModalDatosAdicionalesAction(true));
        dispatch(getDatosAdicionalesAction("EMPLEADOS"));
      },
    },
  ];

  const editRecord = (record) => {
    dispatch(comenzarEditarEmpleadoAction(record));
    dispatch(getTabsDatosAdicionalesAction("EMPLEADOS"));
    dispatch(getDatosAdicionalesAction("EMPLEADOS", record.id));
    dispatch(getDatosAdicionalesDetallesAction("EMPLEADOS", record.id));
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: dispatch,
        action: eliminarEmpleadoAction,
        args: [record],
      },
    ];

    ConfirmModal(callbacks);
  };

  const comisiones = () => {
    dispatch(comenzarListaComisionesAction(selected));
    dispatch(getEmpleadosComisionesAction(selected));
  };

  const verPuestos = (record) => {
    setShowModalPuestos(true);
    setSelected(record);
  };

  const verHorarios = (record) => {
    setShowModalHorarios(true);
    setSelected(record);
  }

  const items = [
    {
      label: "Opciones",
      items: [
        {
          label: "Horarios",
          icon: "pi pi-clock",
          command: () => {
            verHorarios();
          },
        },
        {
          label: "Puestos",
          icon: "pi pi-sitemap",
          command: () => {
            verPuestos();
          },
        },
        {
          label: "Historial comisiones",
          icon: "pi pi-money-bill",
          command: () => {
            comisiones();
          },
        },
      ],
    },
  ];

  const acciones = [
    {
      type: "fragment",
      render: (record) => {
        return (
          <>
            <div style={{ display: "initial", marginLeft: "5px" }}>
              <Menu
                model={items}
                popup
                ref={menuRight}
                id="popup_menu_right"
                popupAlignment="right"
              />
              <PrimeButton
                icon="pi pi-ellipsis-h"
                onClick={(event) => {
                  event.preventDefault();
                  menuRight.current.toggle(event);
                  setSelected(record);
                }}
                size="small"
                severity="info"
                style={{ width: "34px", height: "31px" }}
                tooltip="Más opciones"
                tooltipOptions={{ position: "top" }}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Empleados</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Empleados</h5>
          <DataTable
            columns={columns}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            acciones={acciones}
            state={state}
            loading={loadingEmpleados}
            extraButtons={extraButtons}
          />
        </Card.Body>
      </Card>
      <AgregarEmpleado />
      <EditarEmpleado />
      <Comisiones />'
      <DatosAdicionales tabla="EMPLEADOS" />
      <ModalEmpleadoPuestos
        showModal={showModalPuestos}
        onHide={() => {
          setShowModalPuestos(false);
          setSelected(null);
        }}
        empleado={selected}
      />
      <ModalEmpleadoHorarios
        showModal={showModalHorarios}
        onHide={() => {
          setShowModalHorarios(false);
          setSelected(null);
        }}
        empleado={selected}
      />
    </>
  );
};
