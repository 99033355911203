import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Button as PrimeButton } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import { Calendar } from "primereact/calendar";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "primereact/divider";

export default (props) => {
  const dispatch = useDispatch();

  const [horarios, setHorarios] = useState({
    Lunes: [],
    Martes: [],
    Miercoles: [],
    Jueves: [],
    Viernes: [],
    Sabado: [],
    Domingo: [],
  });

  const [selectedDays, setSelectedDays] = useState(
    Object.keys(horarios).filter((day) => horarios[day].length > 0)
  );

  const [showModal, setShowModal] = useState(false);

  const [edicion, guardar] = useState({
    id_empleado: null,
    horarios: [],
  });

  useEffect(() => {
    guardar({
      ...edicion,
      ...props,
    });
  }, [props]);

  useEffect(() => {
    if (props.empleado) {
      edicion.id_empleado = props.empleado.id;

      guardar({
        ...edicion,
        id_empleado: props.empleado.id,
      });

      getEmpleadoHorarios();
    }
  }, [props.empleado]);

  useEffect(() => {
    setShowModal(props.showModal);

    if (props.showModal === true) {
      //getPuestos();
    }
  }, [props.showModal]);

  const submitForm = (e) => {
    e.preventDefault();

    let horariosRequest = [];

    Object.keys(horarios).forEach((dia) => {
      horarios[dia].forEach((horario) => {
        horariosRequest.push({
          dia: dia,
          hora_inicio: moment.utc(horario.from).format("hh:mm A"),
          hora_fin: moment.utc(horario.to).format("hh:mm A"),
        });
      });
    });

    clienteAxios
      .post("empleados/horarios", {
        id_empleado: edicion.id_empleado,
        horarios: horariosRequest,
      })
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          props.onHide();
          setShowModal(false);
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  const handleClose = () => {
    props.onHide();
    setShowModal(false);
  };

  const timeConstraints = {
    minutes: {
      step: 10,
    },
  };

  const handleTimeChange = (day, index, field, value) => {
    const newHours = { ...horarios };
    newHours[day][index][field] = value;
    setHorarios(newHours);
  };

  const addTimeSlot = (day) => {
    const newHours = { ...horarios };
    newHours[day].push({ from: null, to: null });
    setHorarios(newHours);
  };

  const removeTimeSlot = (day, index) => {
    const newHours = { ...horarios };
    newHours[day].splice(index, 1);
    setHorarios(newHours);
  };

  const getEmpleadoHorarios = () => {
    clienteAxios
      .get("empleados/horarios/" + edicion.id_empleado)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          let horariosMap = {
            Lunes: [],
            Martes: [],
            Miercoles: [],
            Jueves: [],
            Viernes: [],
            Sabado: [],
            Domingo: []
          };

          respuesta.data.data.forEach((horarioRow) => {
            const dateHoraInicio = moment.utc(horarioRow.hora_inicio, "hh:mm A");

            const dateHoraFin = moment.utc(horarioRow.hora_fin, "hh:mm A");

            horariosMap[horarioRow.dia].push({
              from: dateHoraInicio,
              to: dateHoraFin,
            });
          });

          setHorarios(horariosMap);

          setSelectedDays(
            Object.keys(horariosMap).filter((day) => horariosMap[day].length > 0)
          );

          guardar({
            ...edicion,
            horarios: respuesta.data.data,
          });
        }
      });
  };

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Horarios</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          {Object.keys(horarios).map((day) => (
            <>
              <Row className="mb-3">
                <Col key={day} sm={12} md={3}>
                  <Checkbox
                    inputId={day}
                    checked={selectedDays.includes(day)}
                    onChange={(e) => {
                      const newDays = e.checked
                        ? [...selectedDays, day]
                        : selectedDays.filter((d) => d !== day);
                      setSelectedDays(newDays);
                    }}
                  />
                  <label htmlFor={day} style={{ marginLeft: "5px" }}>
                    {day}
                  </label>
                </Col>
                <Col sm={12} md={9}>
                  {selectedDays.includes(day) &&
                    horarios[day].map((slot, index) => (
                      <>
                        <Row key={index}>
                          <Col md={4} sm={6}>
                            <Datetime
                              timeFormat={true}
                              closeOnSelect={false}
                              onChange={(e) =>
                                handleTimeChange(day, index, "from", e)
                              }
                              utc={true}
                              value={slot.from}
                              initialViewMode="time"
                              dateFormat="hh:mm A"
                              timeConstraints={timeConstraints}
                              renderInput={(propsDate, openCalendar) => (
                                <InputGroup>
                                  <Form.Control
                                    required
                                    type="text"
                                    value={
                                      slot.from
                                        ? moment
                                            .utc(slot.from)
                                            .format("hh:mm A")
                                        : ""
                                    }
                                    onFocus={openCalendar}
                                    onChange={(e) =>
                                      handleTimeChange(
                                        day,
                                        index,
                                        "from",
                                        e.target.value
                                      )
                                    }
                                    size="sm"
                                  />
                                </InputGroup>
                              )}
                            />
                          </Col>
                          a
                          <Col md={4} sm={6}>
                            <Datetime
                              timeFormat={true}
                              closeOnSelect={false}
                              onChange={(e) =>
                                handleTimeChange(day, index, "to", e)
                              }
                              utc={true}
                              value={slot.to}
                              initialViewMode="time"
                              dateFormat="hh:mm A"
                              timeConstraints={timeConstraints}
                              renderInput={(propsDate, openCalendar) => (
                                <InputGroup>
                                  <Form.Control
                                    required
                                    type="text"
                                    value={
                                      slot.to
                                        ? moment.utc(slot.to).format("hh:mm A")
                                        : ""
                                    }
                                    onFocus={openCalendar}
                                    onChange={(e) =>
                                      handleTimeChange(
                                        day,
                                        index,
                                        "to",
                                        e.target.value
                                      )
                                    }
                                    size="sm"
                                  />
                                </InputGroup>
                              )}
                            />
                          </Col>
                          <Col md={3} sm={6}>
                            <PrimeButton
                              icon="pi pi-times"
                              rounded
                              text
                              severity="danger"
                              aria-label="Cancel"
                              onClick={() => removeTimeSlot(day, index)}
                            />
                          </Col>
                        </Row>
                      </>
                    ))}
                  {selectedDays.includes(day) && (
                    <PrimeButton
                      label="Añadir otro horario"
                      icon="pi pi-plus"
                      className="p-button-sm p-mt-2"
                      onClick={() => addTimeSlot(day)}
                      type="button"
                      size="small"
                      text
                    />
                  )}
                </Col>
              </Row>

              <Divider />
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Actualizar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
